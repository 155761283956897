import React, {
  Component,
  useEffect,
  useState,
  useCallback,
} from "react";
import {
  Stack,
  Button,
  Container,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
  Badge,
  Collapse,
} from "react-bootstrap";
import CreateSourceForm from "@/components/partials/forms/components/CreateSourceForm";
import EditSourceForm from "@/components/partials/forms/components/EditSourceForm";
import { Source } from "@/store/source/types";
import { all as selectAllSources } from "@/store/source/api-requests";
import {
  MdAdd,
  MdEdit,
  MdOutlineFormatListBulleted,
} from "react-icons/md";
import SourceListItem from "@/components/partials/forms/components/SourceListItem";

const Sources = () => {

  const [createCollapsed, setCreateCollapsed] = useState(true);
  const [sources, setSources] = useState([]);

  const loadSources = useCallback(async () => {

    try {

      const data: any = await selectAllSources();
      setSources(data);

    } catch (e) {

      throw(e);

    }

  }, [sources]);

  useEffect(() => {
    loadSources();
  }, []);

  const onCreate = async (state: any) => {

    console.log("Sources.onCreate", state);
    await loadSources();

  };

  const onChange = async () => {

    console.log("Sources.onChange");
    await loadSources();

  };

  const createCollapse = () => {
    setCreateCollapsed(!createCollapsed);
  };

  return <>
    <Container fluid className="py-4">

      <Row>
        <Col className="text-right">
          <Button
            size="sm"
            onClick={() => createCollapse()}
            aria-controls="createSource"
            aria-expanded={createCollapsed}
          >
            <MdAdd /> Create source
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Collapse in={!createCollapsed}>
            <div id="createSource">
              <CreateSourceForm onCreate={onCreate} />
            </div>
          </Collapse>
        </Col>
      </Row>

    </Container>

    <Container fluid className="py-4">

      <h5>Sources</h5>

      {sources.length > 0 && <div>{sources.map((source: Source, i: number) => (
        <SourceListItem key={source.id} source={source} onChange={onChange} />
      ))}</div>}

    </Container>
  </>;

};

export default Sources;
