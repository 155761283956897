import React from "react";
import {
  Nav as BoostrapNav,
  Navbar,
  NavDropdown,
  Button,
  Container,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import logo from "@/assets/images/main/lockup.png";
import userAccountPicture from "@/assets/images/utilities/user-account-picture.png";
import { logout } from "@/store/user/actions-api";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { UserThunkDispatch } from "@/store/user/types";
import { useNavigate } from "react-router-dom";
import {
  RootState,
  GeneralThunkDispatch,
} from "@/store";
import classNames from "classnames";
import { GoLinkExternal } from "react-icons/go";
import styles from "./Nav.module.scss";

export const Nav = () => {
  const dispatchUser = useDispatch<UserThunkDispatch>();
  const dispatchNote = useDispatch<GeneralThunkDispatch>();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);
  const isLoggedIn = !!user.id;

  const navLogout = async () => {
    await dispatchUser(logout());
    navigate("/user/login");
  };

  const loggedOutTitle = "Admin";
  const loggedInTitle = "Investments";

  const left = <LinkContainer to={!isLoggedIn ? "/user/login" : "/dashboard"}>
    <Navbar.Brand>
      <strong>{!isLoggedIn ? loggedOutTitle : loggedInTitle}</strong>
    </Navbar.Brand>
  </LinkContainer>;

  const rightNotLoggedIn = <Navbar.Collapse id="main-navbar-navigation">
    <BoostrapNav className="ml-auto">
      <LinkContainer to="/user/login">
        <BoostrapNav.Link className="mr-2">Login</BoostrapNav.Link>
      </LinkContainer>
      <LinkContainer to="/user/signup">
        <Button className={classNames("btn-blue", styles.navbarButton)}>
          Signup
        </Button>
      </LinkContainer>
    </BoostrapNav>
  </Navbar.Collapse>;

  const rightLoggedIn = <Navbar.Collapse id="main-navbar-navigation">
    <BoostrapNav className="mr-auto">
      <LinkContainer to="/sources">
        <BoostrapNav.Link className="mr-2">Sources</BoostrapNav.Link>
      </LinkContainer>
    </BoostrapNav>
    <BoostrapNav className="ml-auto">
      <NavDropdown
        className="justify-content-end"
        title={
          <img
            src={userAccountPicture}
            width="35"
            height="35"
            className="d-inline-block align-middle"
            alt="User logi"
            style={{ marginBottom: "2px" }}
          />
        }
        id="basic-nav-dropdown"
      >
        <NavDropdown.Header className="px-2 py-0 small">
          {user.email}
        </NavDropdown.Header>
        <NavDropdown.Divider />
        <LinkContainer to="/dashboard">
          <NavDropdown.Item>Dashboard</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/create-note">
          <NavDropdown.Item>Create Note</NavDropdown.Item>
        </LinkContainer>
        <NavDropdown.Divider />
        <LinkContainer to="/">
          <NavDropdown.Item>App Homepage</NavDropdown.Item>
        </LinkContainer>
        <NavDropdown.Item
          onClick={() => {
            navLogout();
          }}
        >
          Logout
        </NavDropdown.Item>
      </NavDropdown>
    </BoostrapNav>
  </Navbar.Collapse>;

  const right = !isLoggedIn ? rightNotLoggedIn : rightLoggedIn;

  return <Container fluid>
    <Navbar bg="light" expand="lg">
      {left}
      <Navbar.Toggle aria-controls="main-navbar-navigation" />
      {right}
    </Navbar>
  </Container>;

};
