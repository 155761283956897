import React, {
  useEffect,
  PureComponent,
  useState,
} from "react";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import {
  Cell,
  BarChart,
  Bar,
  LineChart,
  Line,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
  LabelList,
} from "recharts";
import { all as selectAllComputed } from "@/store/computed/api-requests";
import { SourceCharts } from "@/components/partials/charts/SourceCharts";

export const Dashboard = () => {
  const { addToast } = useToasts();

  const [data, setData] = useState(null);

  const loadProgramData = async () => {
    try {

      const all: any = await selectAllComputed();
      console.log({ all });
      setData(all);

    } catch (error) {
      addToast(
        "Hmm, there was an error retrieving your data. Please refresh the page and try again.",
        {
          appearance: "error",
        },
      );
    }
  };

  useEffect(() => {
    loadProgramData();
  }, []);

  return (
    <Container className="py-4">

      {data !== null && <SourceCharts data={data} />}

    </Container>
  );
};
