import React, {
  useState,
} from "react";
import {
  Button,
  Collapse,
  Row,
  Col,
  Badge,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {
  MdEdit,
  MdOutlineFormatListBulleted,
  MdDelete,
  MdClose,
} from "react-icons/md";
import EditSourceForm from "@/components/partials/forms/components/EditSourceForm";
import {
  truncate as truncateSource,
  destroy as destroySource,
} from "@/store/source/api-requests";

const SourceListItem = (props: any) => {

  const [collapsed, setCollapsed] = useState(true);
  const source = props.source;

  const collapse = () => {
    console.log("SourceListItem.collapse");
    setCollapsed(!collapsed);
  };

  const onChange = () => {
    console.log("SourceListItem.onChange");
    if (props.onChange) {
      props.onChange();
    }
  };

  const onOrders = () => {
    console.log("SourceListItem.onOrders");
    onChange();
  };

  const onTruncate = async () => {
    console.log("SourceListItem.onTruncate");

    try {

      const truncate = await truncateSource(source);

    } catch (e) {
      throw e;
    }

    if (props.onTruncate) {
      props.onTruncate();
    }
    onChange();
  };

  const onDelete = async () => {
    console.log("SourceListItem.onDelete");

    try {

      const destroy = await destroySource(source);

    } catch (e) {
      throw e;
    }

    if (props.onDelete) {
      props.onDelete();
    }
    onChange();
  };

  const onUpdate = () => {
    console.log("SourceListItem.onUpdate");
    if (props.onUpdate) {
      props.onUpdate();
    }
    onChange();
  };

  return <div className="bg-light border my-4 p-4" onClick={() => console.log(source)}>
    <Row>
      <Col>
        <strong>{source.name}</strong>
        {source.pingedAt && Date.parse(source.pingedAt)
        > Date.now() - (1000 * 60 * 3) ? <Badge bg="success" text="white" pill className="mx-2">Connected</Badge> :
          <Badge bg="danger" text="white" pill className="mx-2">Not connected</Badge>}
      </Col>
      <Col className="text-right">

        <LinkContainer to={`/sources/${source.id}`}>
          <Button
            size="sm"
            className="mx-1"
            variant="secondary"
          >
            <MdOutlineFormatListBulleted /> Source
          </Button>
        </LinkContainer>

        <LinkContainer to={`/sources/${source.id}/orders`}>
          <Button
            size="sm"
            className="mx-1"
            variant="secondary"
          >
            <MdOutlineFormatListBulleted /> Orders
          </Button>
        </LinkContainer>

        <Button
          size="sm"
          className="mx-1"
          onClick={() => collapse()}
          aria-controls="editSource"
          aria-expanded={collapsed}
        ><MdEdit /> Edit</Button>

        <Button
          size="sm"
          variant="warning"
          className="mx-1"
          onClick={() => onTruncate()}
        ><MdDelete /> Truncate</Button>

        <Button
          size="sm"
          variant="danger"
          className="mx-1"
          onClick={() => onDelete()}
        ><MdClose /> Delete</Button>

      </Col>
    </Row>
    <Row>
      <Col>
        <Collapse in={!collapsed}>
          <div id="editSource">
            <EditSourceForm source={source} onUpdate={onUpdate} />
          </div>
        </Collapse>
      </Col>
    </Row>
  </div>;

};

export default SourceListItem;
