import { AxiosResponse } from "axios";
import axios, { setAuthorizationHeader } from "@/common/axios";
import {
  Computed,
} from "@/store/computed/types";

const routeMain = "computed";

export const all = async (data?: { sourceId?: string }): Promise<Computed> => {
  setAuthorizationHeader(axios);
  const result: AxiosResponse<{
    data: { computed: Computed };
  }> = await axios.get(routeMain, { params: data });

  console.log({ result })

  return result.data.data.computed;
};
