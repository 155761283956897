import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Container,
  Table,
} from "react-bootstrap";
import {
  useParams,
} from "react-router-dom";

import {
  all as selectAllOrders,
} from "@/store/order/api-requests";
import { all as selectAllSources } from "@/store/source/api-requests";

const Orders = () => {

  const params = useParams();
  const sourceId = params.sourceId ? +params.sourceId : 0;
  const [orders, setOrders] = useState([]);

  const loadOrders = useCallback(async () => {

    try {

      const data: any = await selectAllOrders({
        sourceId,
      });
      console.log(data);
      setOrders(data);

    } catch (e) {

      throw(e);

    }

  }, [orders]);

  useEffect(() => {
    loadOrders();
  }, []);

  return <div>
    <Container fluid className="py-4">

      <h5>Orders</h5>

      <div>orders length : {orders.length}</div>

      {orders.length > 0 && <Table responsive striped bordered hover size="sm">
        <thead>
        <tr>
          {Object.keys(orders[0])
            .map((key, index) => (
              <th key={index}>{key}</th>
            ))}
        </tr>
        </thead>
        <tbody>
        {orders.map((order, orderIndex) => (
          <tr key={orderIndex}>
            {Object.keys(order)
              .map((key, keyIndex) => (
                <td key={keyIndex}>{order[key]}</td>
              ))}
          </tr>
        ))}
        </tbody>
      </Table>}

      {orders.length === 0 && <div><strong>No orders</strong></div>}

    </Container>
  </div>;

};

export default Orders;
