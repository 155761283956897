import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./NotFound.module.scss";

export const NotFound = () => (
  <section className={styles.section}>
    <Container>
      <Row className="justify-content-center">
        <Col lg={6}>
          404 - Page Not Found
        </Col>
      </Row>
    </Container>
  </section>
);
