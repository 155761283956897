import React, {
  useState,
} from "react";
import {
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { SourceCreate } from "@/store/source/types";
import { create } from "@/store/source/api-requests";

const CreateSourceForm = (props: any) => {

  const [name, setName] = useState("");
  const [hash, setHash] = useState("");

  /**
   * handleSubmit
   * @param event
   */
  const handleCreate = async (event: any): Promise<boolean> => {

    event.preventDefault();

    if (name === "" || hash === "") {
      return false;
    }

    const source: SourceCreate = {
      name,
      hash,
    };

    try {

      await create(source);
      await props.onCreate(source);
      setName("");
      setHash("");
      return true;

    } catch (e) {

      throw(e);

    }

  };

  /**
   * generateHash
   */
  const generateHash = () => {

    let hash = "";
    const start = 33;
    const stop = 126;
    const total = stop - start;
    const n = 64;
    for (let i = 0; i < n; i++) {
      const decimal = Math.round(Math.random() * total) + start;
      hash += String.fromCharCode(decimal);
    }

    setHash(hash);

  };

  return <div>
    <Form>

      <Form.Group className="py-4">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" placeholder="Name" value={name} onChange={(event) => setName(event.target.value)} />
      </Form.Group>

      <Form.Group className="py-4">
        <Form.Label>Hash</Form.Label>
        <InputGroup>
          <Form.Control placeholder="Hash" type="text" value={hash} onChange={(event) => setHash(event.target.value)} />
          <Button variant="secondary" onClick={generateHash}>
            Generate
          </Button>
        </InputGroup>
      </Form.Group>

      <Button variant="primary" type="submit" className="btn-block" onClick={(event) => handleCreate(event)}>
        Create
      </Button>

    </Form>
  </div>;

};

export default CreateSourceForm;
