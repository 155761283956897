import React, {
  useEffect,
  useState,
} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "@/assets/css/app.scss";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { JwtDecodeData } from "@/store/user/types";
import { setUser } from "@/store/user/actions-store";
import { runAxiosAuthInterceptor } from "@/common/axios-auth-interceptor";
import { useToasts } from "react-toast-notifications";
import {
  RootState,
  GeneralThunkDispatch,
} from "@/store";
import { Nav } from "@/components/partials/main/Nav";
import { Footer } from "@/components/partials/main/Footer";
import { NotFound } from "@/components/layouts/main/NotFound";
import { Maintenance } from "@/components/layouts/main/Maintenance";
import { Home } from "@/components/layouts/pages/Home";
import { Login } from "@/components/user/components/Login";
import { Signup } from "@/components/user/components/Signup";
import { Forgot } from "@/components/user/components/Forgot";
import { Reset } from "@/components/user/components/Reset";
import { Dashboard } from "@/components/layouts/program/Dashboard";
import Sources from "@/components/layouts/program/Sources";
import Source from "@/components/layouts/program/Source";
import Orders from "@/components/layouts/program/Orders";
import jwtDecode from "jwt-decode";

export const App = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch<GeneralThunkDispatch>();
  const { addToast } = useToasts();
  const [isLoadingAppState, setIsLoadingAppState] = useState(true);
  const [ranInitialUserCheck, setRanInitialUserCheck] = useState(false);

  // Sometimes your app with need to run some actions on page refresh.
  // Right now we're going to load the base notes in the dashboard instead
  // const loadProgramData = async () => {};

  // Check for a possible user...
  const checkUserStatus = async () => {

    console.log("checkUserStatus");

    try {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      if (accessToken != null && refreshToken != null) {
        const decoded: JwtDecodeData = jwtDecode(accessToken);
        dispatch(setUser(decoded.data));
      }
      return Promise.resolve();
    } catch (error) {
      addToast(
        "Hmm, there was an error retrieving your data. Please refresh the page and try again.",
        {
          appearance: "error",
        },
      );
    } finally {
      setIsLoadingAppState(false);
    }
  };

  // Two important things here, attach our axios interceptor and check for user
  useEffect(() => {
    runAxiosAuthInterceptor();
    checkUserStatus();
  }, []);

  useEffect(() => {
    // If we haven't checked for the user yet, and the app is done loading,
    // then check for the user and run loadProgramData if needed.
    if (!ranInitialUserCheck && !isLoadingAppState && user.id) {
      // loadProgramData();
      setRanInitialUserCheck(true);
    }
  }, [user]);

  // Array of paths where we don't want to show the nav and footer
  const metaPaths = ["/maintenance"];

  // The logic here is that if we are loading the initial app state and
  // haven't checked for a user yet, let's just show a blank page. Otherwise,
  // show the home page, but take care to only allow traveling to user links
  // when a user is logged in.
  return isLoadingAppState && !ranInitialUserCheck ? (
    <div />
  ) : (
    <Router>
      <div className="page-wrapper">
        {!metaPaths.includes(window.location.pathname) && <Nav />}
        <div className="main-content-wrapper" role="main" aria-label="Main">
          <Routes>
            <Route path="/user/login" element={<Login />} />
            <Route path="/user/signup" element={<Signup />} />
            <Route path="/user/forgot" element={<Forgot />} />
            <Route path="/user/reset" element={<Reset />} />

            <Route path="/dashboard" element={user.id ? <Dashboard /> : <Login />} />

            <Route path="/sources" element={user.id ? <Sources /> : <Login />} />
            <Route path="/sources/:sourceId" element={user.id ? <Source /> : <Login />} />
            <Route path="/sources/:sourceId/orders" element={user.id ? <Orders /> : <Login />} />

            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="*" element={user.id ? <Dashboard /> : <Login />} />
            <Route element={<NotFound />} />

          </Routes>
        </div>
        {!metaPaths.includes(window.location.pathname) && <Footer />}
      </div>
    </Router>
  );
};
