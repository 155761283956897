import React, {
  useEffect,
  useState,
} from "react";
import { LoginForm } from "@/components/user/forms/LoginForm";
import { KnownLoginForm } from "@/components/user/forms/KnownLoginForm";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";

export const Login = () => {

  return <Container className="py-4">
    <Row>
      <Col>
        <LoginForm />
      </Col>
    </Row>
  </Container>;
};
