// Because we want to customize the baseURL for axios, we export an instance
// here and use *it* as axios throughout our app.

import axios, { AxiosInstance } from "axios";

// If using the local external option when serving the frontend,
// you need to provide the base url the external serving provides.
const defaultOptions = {
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
};

// Create instance
const instance = axios.create(defaultOptions);

instance.interceptors.response.use(undefined, err => {
  console.log("axios.interceptors");
  const error = err.response;
  // if error is 401
  if (error.status === 401 && error.config &&
    !error.config.__isRetryRequest) {
    console.log("axios 401");

    // request for a new token
    // return getAuthToken().then(response => {
    //   // update the error config with new token
    //   error.config.__isRetryRequest = true;
    //   error.config.headers.token= localStorage.getItem("accessToken");
    //   return client(error.config);
    // });

    document.location = "/user/login";

  }
});

export default instance;

// Here's our authorization header helper. It'll read the localStorage and
// use a potentially available accessToken to refresh our user
export const setAuthorizationHeader = (a: AxiosInstance) => {
  // functional/immutable-data
  // eslint-disable-next-line
  a.defaults.headers.common.Authorization = `Bearer ${
    localStorage.getItem("accessToken") || ""
  }`;
};

export const setSourceAuthorizationHeader = (a: AxiosInstance, email: string, hash: string) => {
  // functional/immutable-data
  // eslint-disable-next-line
  a.defaults.headers.common.Authorization = `Bearer ${email} ${hash}`;
};
