import React from "react";
import { FallbackProps } from "react-error-boundary";

export const ErrorBoundaryComponent = ({
  error,
}: FallbackProps) => (
  <div style={{ padding: "2rem" }}>
    <p>
      <strong>Error:</strong> {error ? error.toString() : undefined}
    </p>
  </div>
);
