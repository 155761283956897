import React from "react";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";

export const Footer = () => (
  <Container className="p-4" fluid>
    <Row>
      <Col>
        <p>
          Sokod
        </p>
      </Col>
    </Row>
  </Container>
);
