import { AxiosResponse } from "axios";
import axios, { setAuthorizationHeader } from "@/common/axios";
import {
  Source,
  SourceCreate,
  SourceUpdate,
  SourceDelete,
  SourceTruncate,
} from "@/store/source/types";

const routeMain = "sources";

export const all = async (): Promise<Source[]> => {
  setAuthorizationHeader(axios);
  const result: AxiosResponse<{
    data: { sources: Source[] };
  }> = await axios.get(routeMain, {});

  return result.data.data.sources;
};

export const create = async (data: SourceCreate): Promise<Source> => {
  setAuthorizationHeader(axios);
  const result: AxiosResponse<{
    data: { source: Source };
  }> = await axios.post(routeMain, data);

  console.log('Source create', result)

  return result.data.data.source;
};

export const update = async (data: SourceUpdate): Promise<Source> => {
  setAuthorizationHeader(axios);
  const result: AxiosResponse<{
    data: { source: Source };
  }> = await axios.put(`${routeMain}/${data.id}`, data);

  console.log({ result });
  return result.data.data.source;
};

export const destroy = async (data: SourceDelete): Promise<Source> => {
  setAuthorizationHeader(axios);
  const result: AxiosResponse<{
    data: { source: Source };
  }> = await axios.delete(`${routeMain}/${data.id}`);

  return result.data.data.source;
};

export const truncate = async (data: SourceTruncate): Promise<Source> => {
  setAuthorizationHeader(axios);
  const result: AxiosResponse<{
    data: { source: Source };
  }> = await axios.delete(`${routeMain}/truncate/${data.id}`);

  return result.data.data.source;
};

// export const all = async (data: NotesQuery): Promise<Note[]> => {
//   setAuthorizationHeader(axios);
//   const result: AxiosResponse<{
//     data: { notes: Note[] };
//   }> = await axios.get(routeMain, { params: data });
//
//   return result.data.data.notes;
// };
//
// export const find = async (data: number): Promise<Note> => {
//   setAuthorizationHeader(axios);
//   const result: AxiosResponse<{
//     data: { note: Note };
//   }> = await axios.get(`${routeMain}/${data}`);
//
//   return result.data.data.note;
// };
//
// export const create = async (data: NoteCreatePost): Promise<Note> => {
//   setAuthorizationHeader(axios);
//   const result: AxiosResponse<{
//     data: { note: Note };
//   }> = await axios.post(routeMain, data);
//
//   return result.data.data.note;
// };
//
// export const update = async (data: Note): Promise<Note> => {
//   const putRequest: NoteEditPut = {
//     title: data.title,
//     content: data.content,
//   };
//
//   setAuthorizationHeader(axios);
//   const result: AxiosResponse<{
//     data: { note: Note };
//   }> = await axios.put(`${routeMain}/${data.id}`, putRequest);
//
//   return result.data.data.note;
// };
//
// export const del = async (data: Note): Promise<Note> => {
//   setAuthorizationHeader(axios);
//   await axios.delete(`${routeMain}/${data.id}`);
//   return data;
// };
