import React, {
  useState,
} from "react";
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Collapse,
} from "react-bootstrap";
import { SourceUpdate } from "@/store/source/types";
import { update } from "@/store/source/api-requests";

const EditSourceForm = (props: any) => {

  const [name, setName] = useState(props.source.name);
  const [hash, setHash] = useState("");

  /**
   * handleSubmit
   * @param event
   */
  const onUpdate = async (event: any): Promise<boolean> => {

    console.log("onUpdate");

    if (name === "") {
      return false;
    }

    const source: SourceUpdate = {
      id: props.source.id,
      name,
    };

    if (hash !== "") {
      source.hash = hash;
    }

    console.log("Updating source", source);

    try {

      await update(source);
      if (props.onUpdate) {
        props.onUpdate(source);
      }
      return true;

    } catch (e) {

      throw(e);

    }

  };

  /**
   * generateHash
   */
  const generateHash = () => {

    let hash = "";
    const start = 33;
    const stop = 126;
    const total = stop - start;
    const n = 64;
    for (let i = 0; i < n; i++) {
      const decimal = Math.round(Math.random() * total) + start;
      hash += String.fromCharCode(decimal);
    }
    const replaced = [
      34,
      39,
      92,
      96,
      124,
    ];

    replaced.forEach((char) => hash.replace(String.fromCharCode(char), "+"));

    setHash(hash);

  };

  return <div>
    <Form>

      <Form.Group className="py-4">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" placeholder="Name" value={name} onChange={(event) => setName(event.target.value)} />
      </Form.Group>

      <Form.Group className="py-4">
        <Form.Label>Hash</Form.Label>
        <InputGroup>
          <Form.Control placeholder="Hash" type="text" value={hash} onChange={(event) => setHash(event.target.value)} />
          <Button variant="secondary" onClick={generateHash}>
            Generate
          </Button>
        </InputGroup>
      </Form.Group>

      <Button variant="primary" type="button" className="btn-block" onClick={(event) => onUpdate(event)}>
        Update
      </Button>

    </Form>
  </div>;

};

export default EditSourceForm;
