import { AxiosResponse } from "axios";
import axios, { setAuthorizationHeader } from "@/common/axios";
import {
  Order,
  OrderCreate,
  OrdersQuery,
} from "@/store/order/types";
import { Source } from "@/store/source/types";

const routeMain = "orders";

export const all = async (data: OrdersQuery): Promise<Order[]> => {
  setAuthorizationHeader(axios);
  const result: AxiosResponse<{
    data: { orders: Order[] };
  }> = await axios.get(routeMain, { params: data });

  return result.data.data.orders;
};

// export const count = async (email: string, hash: string): Promise<number> => {
//   setSourceAuthorizationHeader(axios, email, hash);
//   const result: AxiosResponse<{
//     data: { count: number };
//   }> = await axios.get(`${routeMain}/count`);
//
//   return result.data.data.count;
// };
//
// export const create = async (data: OrderCreate, email: string, hash: string): Promise<Order> => {
//   setSourceAuthorizationHeader(axios, email, hash);
//   const result: AxiosResponse<{
//     data: { order: Order };
//   }> = await axios.post(routeMain, data);
//
//   return result.data.data.order;
// };

// export const all = async (data: NotesQuery): Promise<Note[]> => {
//   setAuthorizationHeader(axios);
//   const result: AxiosResponse<{
//     data: { notes: Note[] };
//   }> = await axios.get(routeMain, { params: data });
//
//   return result.data.data.notes;
// };
//
// export const find = async (data: number): Promise<Note> => {
//   setAuthorizationHeader(axios);
//   const result: AxiosResponse<{
//     data: { note: Note };
//   }> = await axios.get(`${routeMain}/${data}`);
//
//   return result.data.data.note;
// };
//
// export const create = async (data: NoteCreatePost): Promise<Note> => {
//   setAuthorizationHeader(axios);
//   const result: AxiosResponse<{
//     data: { note: Note };
//   }> = await axios.post(routeMain, data);
//
//   return result.data.data.note;
// };
//
// export const update = async (data: Note): Promise<Note> => {
//   const putRequest: NoteEditPut = {
//     title: data.title,
//     content: data.content,
//   };
//
//   setAuthorizationHeader(axios);
//   const result: AxiosResponse<{
//     data: { note: Note };
//   }> = await axios.put(`${routeMain}/${data.id}`, putRequest);
//
//   return result.data.data.note;
// };
//
// export const del = async (data: Note): Promise<Note> => {
//   setAuthorizationHeader(axios);
//   await axios.delete(`${routeMain}/${data.id}`);
//   return data;
// };
