import {
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Cell,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, {
  useEffect,
  useState,
} from "react";
import { useToasts } from "react-toast-notifications";
import { all as selectAllComputed } from "@/store/computed/api-requests";

type serieType = {
  date: string,
  profitability: number | null,
  profits: number | null,
  cumulative: number | null,
  funds: number | null,
  deposits: number | null
}

type seriesType = serieType[]

type indicatorsType = {
  todayProfitability: number,
  todayProfits: number,
  todayCumulative: number,
  todayFunds: number,
  todayDeposits: number,
  weeklyProfitability: number,
  weeklyDailyProfitability: number,
  weeklyMonthlyProfitability: number,
  weeklySixMonthsProfitability: number,
  weeklyOneYearProfitability: number,
  weeklyProfits: number,
  weeklyOneMonthProfits: number,
  weeklySixMonthsProfits: number,
  weeklyOneYearProfits: number,
  monthlyProfitability: number,
  monthlyDailyProfitability: number,
  monthlyWeeklyProfitability: number,
  monthlySixMonthsProfitability: number,
  monthlyOneYearProfitability: number,
  monthlyProfits: number,
  monthlySixMonthsProfits: number,
  monthlyOneYearProfits: number,
}

type sourceChartData = {
  series: seriesType,
  indicators: indicatorsType
}

const fontSize = 12;
const colSize = 4;
const days = 60;

const thousandSeparator = (x: string | number) => {
  return x.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const SourceCharts = (props: any) => {

  const data: sourceChartData = props.data;
  console.log({ data });

  const todayIndicators = [
    {
      title: "Today profits",
      value: `${thousandSeparator(data.indicators.todayProfits.toFixed(2))} $`,
    },
    {
      title: "Today profitability",
      value: `${data.indicators.todayProfitability > 0 ? `+` : ``}${data.indicators.todayProfitability.toFixed(2)} %`,
    },
    {
      title: "Cumulative profits",
      value: `${thousandSeparator(data.indicators.todayCumulative.toFixed(2))} $`,
    },
    {
      title: "Funds",
      value: `${thousandSeparator(data.indicators.todayFunds.toFixed(2))} $`,
    },
    {
      title: "Deposits",
      value: `${thousandSeparator(data.indicators.todayDeposits.toFixed(2))} $`,
    },
  ];

  const weeklyIndicators = [
    {
      title: "Daily profitability",
      value: `${data.indicators.weeklyDailyProfitability > 0 ? `+` : ``}${data.indicators.weeklyDailyProfitability.toFixed(2)} %`,
    },
    {
      title: "Weekly profitability",
      value: `${data.indicators.weeklyProfitability > 0 ? `+` : ``}${data.indicators.weeklyProfitability.toFixed(2)} %`,
    },
    {
      title: "Monthly profitability",
      value: `${data.indicators.weeklyMonthlyProfitability > 0 ? `+` : ``}${data.indicators.weeklyMonthlyProfitability.toFixed(2)} %`,
    },
    {
      title: "Six months profitability",
      value: `${data.indicators.weeklySixMonthsProfitability > 0 ? `+` : ``}${data.indicators.weeklySixMonthsProfitability.toFixed(2)} %`,
    },
    {
      title: "One year profitability",
      value: `${data.indicators.weeklyOneYearProfitability > 0 ? `+` : ``}${data.indicators.weeklyOneYearProfitability.toFixed(2)} %`,
    },
    {
      title: "Weekly profits",
      value: `${thousandSeparator(data.indicators.weeklyProfits.toFixed(2))} $`,
    },
    {
      title: "One month profits",
      value: `${thousandSeparator(Math.round(data.indicators.weeklyOneMonthProfits))} $`,
    },
    {
      title: "Six months profits",
      value: `${thousandSeparator(Math.round(data.indicators.weeklySixMonthsProfits))} $`,
    },
    {
      title: "One year profits",
      value: `${thousandSeparator(Math.round(data.indicators.weeklyOneYearProfits))} $`,
    },
  ];

  const monthlyIndicators = [
    {
      title: "Daily profitability",
      value: `${data.indicators.monthlyDailyProfitability > 0 ? `+` : ``}${data.indicators.monthlyDailyProfitability.toFixed(2)} %`,
    },
    {
      title: "Weekly profitability",
      value: `${data.indicators.monthlyWeeklyProfitability > 0 ? `+` : ``}${data.indicators.monthlyWeeklyProfitability.toFixed(2)} %`,
    },
    {
      title: "Monthly profitability",
      value: `${data.indicators.monthlyProfitability > 0 ? `+` : ``}${data.indicators.monthlyProfitability.toFixed(2)} %`,
    },
    {
      title: "Six months profitability",
      value: `${data.indicators.monthlySixMonthsProfitability > 0 ? `+` : ``}${data.indicators.monthlySixMonthsProfitability.toFixed(2)} %`,
    },
    {
      title: "One year profitability",
      value: `${data.indicators.monthlyOneYearProfitability > 0 ? `+` : ``}${data.indicators.monthlyOneYearProfitability.toFixed(2)} %`,
    },
    {
      title: "Monthly profits",
      value: `${thousandSeparator(data.indicators.monthlyProfits.toFixed(2))} $`,
    },
    {
      title: "Six months profits",
      value: `${thousandSeparator(Math.round(data.indicators.monthlySixMonthsProfits))} $`,
    },
    {
      title: "One year profits",
      value: `${thousandSeparator(Math.round(data.indicators.monthlyOneYearProfits))} $`,
    },
  ];

  const indicatorsCategories = [
    {
      title: "Today",
      items: todayIndicators,
      lg: 4,
      md: 6,
    },
    {
      title: "Last week",
      items: weeklyIndicators,
      lg: 4,
      md: 6,
    },
    {
      title: "Last month",
      items: monthlyIndicators,
      lg: 4,
      md: 6,
    },
  ];

  return (
    <>

      {indicatorsCategories.map((category, i1) => (
        <div key={i1}>
          <h6>{category.title}</h6>

          <Container fluid className="pb-4">
            <Row>

              {category.items.map((indicator, i2) => (
                <Col lg={category.lg} md={category.md} className="py-2" key={i2}>
                  <Card>
                    <Card.Header>{indicator.title}</Card.Header>
                    <Card.Body>
                      <Card.Text className="text-center h1">{indicator.value}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}

            </Row>
          </Container>
        </div>
      ))}


      <h6>Profitability</h6>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          syncId="synchronize"
          width={500}
          height={300}
          data={data.series.filter((datum:any, index:number)=> index > data.series.length - days)}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="date" fontSize={fontSize} />
          <YAxis fontSize={fontSize} domain={['auto', 'auto']} />
          <Tooltip />
          <Bar dataKey="profitability" isAnimationActive={false} stroke="#333" strokeWidth={1}>
            {data.series.map((datum: any, entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  datum.profitability > 0
                    ? "green"
                    : "red"
                }
              />
            )).filter((datum:any, index:number)=> index > data.series.length - days)}
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      <h6>Profits</h6>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          syncId="synchronize"
          width={500}
          height={300}
          data={data.series.filter((datum:any, index:number)=> index > data.series.length - days)}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="date" fontSize={fontSize} />
          <YAxis fontSize={fontSize} domain={['auto', 'auto']} />
          <Tooltip />
          <Bar dataKey="profits" stroke="#333" fill="url(#splitColor)" isAnimationActive={false}>
            {data.series.map((datum: any, entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  datum.profits > 0
                    ? "green"
                    : "red"
                }
              />
            )).filter((datum:any, index:number)=> index > data.series.length - days)}
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      <h6>Cumulative profits</h6>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          syncId="synchronize"
          width={500}
          height={300}
          data={data.series.filter((datum:any, index:number)=> index > data.series.length - days)}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="date" fontSize={fontSize} />
          <YAxis fontSize={fontSize} domain={['auto', 'auto']} />
          <Tooltip />
          <Line connectNulls={true} strokeWidth={2} dataKey="cumulative" stroke="#333" activeDot={{ r: 8 }} dot={false} isAnimationActive={false} />
        </LineChart>
      </ResponsiveContainer>

      <h6>Funds vs deposits</h6>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          syncId="synchronize"
          width={500}
          height={300}
          data={data.series.filter((datum:any, index:number)=> index > data.series.length - days)}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="date" fontSize={fontSize} />
          <YAxis fontSize={fontSize} domain={['auto', 'auto']} />
          <Tooltip />
          <Line connectNulls={true} strokeWidth={2} dataKey="funds" stroke="green" activeDot={{ r: 8 }} dot={false} isAnimationActive={false} />
          <Line connectNulls={true} strokeWidth={2} dataKey="deposits" stroke="red" activeDot={{ r: 8 }} dot={false} isAnimationActive={false} />
        </LineChart>
      </ResponsiveContainer>

    </>
  );
};
