import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./Maintenance.module.scss";

export const Maintenance = () => (
  <section className={styles.section}>
    <Container>
      <Row className="justify-content-center">
        <Col lg={6}>
          <h2>Maintenance</h2>
        </Col>
      </Row>
    </Container>
  </section>
);
