import React, {
  useEffect,
  useState,
} from "react";
import {
  Container,
} from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { all as selectAllComputed } from "@/store/computed/api-requests";
import { SourceCharts } from "@/components/partials/charts/SourceCharts";
import { useParams } from "react-router-dom";

const Source = () => {
  const { addToast } = useToasts();

  const params = useParams();
  const [data, setData] = useState(null);

  const loadProgramData = async () => {
    try {

      const sourceId = params.sourceId;

      const all: any = await selectAllComputed({ sourceId });
      console.log({ all });
      setData(all);

    } catch (error) {
      addToast(
        "Hmm, there was an error retrieving your data. Please refresh the page and try again.",
        {
          appearance: "error",
        },
      );
    }
  };

  useEffect(() => {
    loadProgramData();
  }, []);

  return (
    <>
      <Container fluid className="py-4">

        {data !== null && <SourceCharts data={data} />}

      </Container>
    </>
  );
};

export default Source;
