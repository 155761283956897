import React, {
  useEffect,
  useState,
} from "react";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import { login } from "@/store/user/actions-api";
import {
  UserLoginPost,
  UserLoginPostValidation,
} from "@/store/user/api-types";
import { UserThunkDispatch } from "@/store/user/types";
import { ServerError } from "@/common/api";
import {
  useNavigate,
  Link,
} from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Card,
  Button,
  ListGroup,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import {
  MdEdit,
  MdOutlineFormatListBulleted,
  MdDelete,
  MdClose,
} from "react-icons/md";

export const LoginForm = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const { addToast } = useToasts();
  const dispatch = useDispatch<UserThunkDispatch>();

  const handleLogin = async (event: any) => {

    event.preventDefault();
    console.log({ event });

    try {
      setIsLoading(true);
      await dispatch(login({
        username,
        password,
      }));

      navigate("/dashboard");
    } catch (error) {
      const e = error as ServerError;
      if (e && (e.error || e.errors)) {
        //
      }

      addToast("Hmm, those details don't seem right. Please try again.", {
        appearance: "error",
      });

      setIsLoading(false);
    }

  };

  const [logins, setLogins] = useState([]);

  const loadLogins = () => {
    const storedLogins = localStorage.logins ?? "[]";
    setLogins(JSON.parse(storedLogins));
  };

  useEffect(() => {
    loadLogins();
  }, []);

  const removeLogin = (login: string) => {
    const tab: any[] = logins;
    const i = tab.indexOf(login);
    if (i >= 0) {
      tab.splice(i, 1);
      localStorage.logins = JSON.stringify(tab);
      loadLogins();
    }
  };

  return (
    <>
      <Container className="py-4">

        <Form>

          <Row className="justify-content-center">
            <Col lg={6}>
              <h1>Login</h1>
            </Col>

            {logins.length > 0 && (
              <>
                <div className="w-100" />

                <Col lg={6} className="mb-5">

                  <Form.Group className="py-2">
                    <Form.Label>Accounts</Form.Label>
                  </Form.Group>

                  <ListGroup>
                    {logins.map((login, index) => (
                      <ListGroup.Item key={index} action onClick={(e) => e.preventDefault()}>
                        <Row>

                          <Col xs={9} onClick={() => setUsername(login)}>
                            {login}
                          </Col>

                          <Col xs={3} className="text-right">
                            <MdClose onClick={() => removeLogin(login)} />
                          </Col>

                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>

                </Col>
              </>
            )}

            <div className="w-100" />

            <Col lg={6}>
              <Form.Group className="py-2">
                <Form.Label>Username</Form.Label>
                <Form.Control type="text" placeholder="Username" value={username} onChange={(event) => setUsername(event.target.value)} />
              </Form.Group>
            </Col>

            <div className="w-100" />

            <Col lg={6}>
              <Form.Group className="py-4">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} />
              </Form.Group>
            </Col>

            <div className="w-100" />

            <Col lg={6}>

              <Button variant="primary" type="submit" className="btn-block" onClick={(event) => handleLogin(event)}>
                Login
              </Button>

            </Col>
          </Row>

        </Form>

      </Container>
      <Container className="py-4">
        <Row className="justify-content-center">
          <Col lg={6}>
            <Link to="/user/forgot">Forgot Password</Link> {" | "}
            <Link to="/user/signup">Signup</Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};
